.wf-loading {
	p, span, a {
		visibility: hidden;
	}
}
.wf-active, .wf-inactive {
	p, span, a {
		visibility: visible;
	}
}

header {
    background: url(../img/header_bg.png) no-repeat top center;
    position: absolute;
    width: 100%;
    height: 100%;
	overflow: hidden;
    
    img {
        margin: auto;
        display: block;
    }
    
    .btn__back {
        position: absolute;
        top: 0;
        left: 0;
		padding: 10px;
		z-index: 200;
    }
	
	.logo {
		margin-top: 70px;
	}
}
body.bg_dark {
	background-color: #312e2f;
}

.background-top {
	background-color: #312e2f;
	height: 354px;
	width: 100%;
	position: absolute;
	min-width: 480px;
}
.header {
    margin-bottom: 55px;
}

.results .header {
    margin-bottom: 0;
}

.no_results a {
	font-size: 0.8em;
	color: #39393b;
	text-decoration: underline;
	text-transform: uppercase;
}

.no_results {
	font-size: 1.2em;
	color: #39393b;
	padding: 20px 0;
	text-align: center;
	
	div {
		margin-bottom: 10px;
	}
}

.no-padding {
    padding: 0;   
}

.background__red {
    background-color:#ec2028;
}

.toggle__container {
    padding-bottom: 20px;   
}

.refine__results {
    font-family: "brandon-grotesque", sans-serif; 
    font-size: 16px;
}

.xdebug-var-dump {
    color: #373737;
}   

.inline__toggle {
    display: inline;  
    position: relative;
    top: 11px;
    margin: 10px 6px 10px 0;   
}

.switch-on {
  position: absolute;
  left: -50px;
  top: 6px;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.switch-off {
  position: absolute;
  left: -28px;
  top: 6px;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.line-break {
    width: 100%;
    height: 1px;
    background: #ffffff;
    margin: rem-calc(12) 0 30px;
    
    &.black {
        background-color: #373737;   
    }
    
    span {
        padding: 10px;
        background-color:#ec2028;
        position: relative;
        top: -12px;
    }   
}

.main {
    position: relative;
    top: 186px;
    
    &.results {
        top: 146px;
    }
}

.row-main {
    padding: rem-calc(10);
}

.instructions { 
    font-size: 21px;
    text-transform: uppercase;
    line-height: 22px;
}

.event__distance {
    font-size: 26px; 
    margin-bottom: 0px;
    line-height: 28px;
}

.click-target {
	cursor: pointer;
}

.date {
    font-size: 20px;
}


.form__layout {
    input {
        border: none;
        font-size: 21px;
        padding: 30px;   
        font-family: "brandon-grotesque", sans-serif;
    }
    
    .address input {
        margin-bottom: 10px;   
    }
    
    .state {
        padding-right: 5px;  
    }

    .zip {
        padding-left: 5px;  
    }
    
    .find {
        margin-top: 35px;   
    }
    
    .button {
        font-family:  "din-condensed-web", Helvetica, Roboto, Arial, sans-serif;
        font-size: 21px;
        padding: 30px;
        display: block;
        color: #fffefc;
        background-color:#ec2028;
        text-transform: uppercase;
        border: 2px solid #fffefc;
        border-radius: 2px;
    }
}

#events {
	background-color: #f6f7f4;
}

.date__single {
	padding-top: 40px;
}

.date__single,
.event__single {
    
    span {
        color: #39393b; 
        text-transform: uppercase;
        
        &.location {
            padding-top: 5px; 
        }
        
        &.text__sm {
            font-size: 13px;      
            position: relative;
            top: -7px;
        }
        
        &.text__md {
            font-size: 16px;
            
            &.super {
                position: relative;
                top: -10px;
            }
        }
        
        &.text__lg {
            font-size: 31px;
        }
    }
    
    .ellipsis {
        text-overflow: ellipsis;
        display: block;

        white-space: nowrap;
        overflow: hidden;
    }
    
    .date__date {
        font-size: 20px;
        color: #ec2028;
        text-transform: uppercase; 
        margin-bottom: 12px;
        display: inline-block;
    }
    
    .date__location {
        padding-left: 0;
		padding-right: 0;
    }
	
	.date__time {
		padding-left: 0;
	}
}

.template {
	display: none;
}

.messages p {
    background: #fffefc;
    color: #ec2028; 
    padding: 14px;
}

.footer {
	background-color: rgba(236, 32, 40, 0.9);
	position: fixed;
	text-align: center;
	bottom: 0;
	left: 0;
	right: 0;
	
	a {
		display: block;
		padding: 10px 0;
		width: 100%;
		text-decoration: underline;
		font-size: 1.1em;
		text-transform: uppercase;
	}
}